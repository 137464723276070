/* eslint-disable react/jsx-props-no-multi-spaces */
import React from 'react';
import slugify from 'slugify';

import {
  ReactiveBase,
  ResultList,
} from '@appbaseio/reactivesearch';

import settings from '../helpers/settings';

import ExplorerNavigation from '../components/ExplorerNavigation';
import ExplorerFilters from '../components/ExplorerFilters';
import ExplorerMap from '../components/ExplorerMap';
import Layout from '../components/layout';

import { allFieldsBut } from '../helpers/explorerFilters';

import './Explorer.scss';

const stripHTMLTags = str => str.replace(/(<([^>]+)>)/gi, '');

const slugOptions = {
  replacement: '-', // replace spaces with replacement
  remove: /[*+~./()'"!:@]/g, // regex to remove characters
  lower: true, // result in lower case
};

const index = 'zae';
const esUrl = settings.ELASTICSEARCH_HOST;

const preRender = typeof window === 'undefined';

class ExplorerPage extends React.Component {
  render () {
    if (preRender) {
      return null;
    }
    return (
      <Layout
        full
        topBarProps={{
          pageTitle: "Exporer/Filtrer des espaces d'activités",
        }}
      >

        <ExplorerNavigation />

        <ReactiveBase
          app={index}
          url={esUrl}

          className="explorer-layout"
        >
          <div className="explorer-layout-pane explorer-layout-left">
            <ExplorerMap />
            <ExplorerFilters />
          </div>

          <div
            id="scroller"
            className="explorer-layout-pane explorer-layout-right"
          >
            <ResultList
              componentId="searchresult"

              dataField="nom_ppal.keyword"
              react={{ and: [...allFieldsBut('')] }}
              sortBy="asc"

              includeFields={[
                'nom_ppal',
                'id_eae',
                'voc_dom',
                'libepci',
              ]}

              scrollTarget="scroller"
              size={30}
              pagination={false}
              showResultStats

              loader={<span>Chargement des résultats…</span>}
              onNoResults={<span>Aucune correspondance</span>}

              onData={({ nom_ppal: title, id_eae: idEae, voc_dom: vocDom, libepci }) => ({
                title,
                description: [vocDom, libepci].join(' - '),
                url: `/espaces-d-activites/${stripHTMLTags(idEae)}/${slugify(stripHTMLTags(title), slugOptions)}${'.html'}`,
              })}

              onResultStats={(total, took) => `${total} résultat${total > 1 ? 's' : ''} (${took}ms)`}

              className="explorer-result-list"
              innerClass={{
                resultsInfo: 'explorer-result-list-stats',
              }}
            />
          </div>
        </ReactiveBase>
      </Layout>
    );
  }
}

export default ExplorerPage;
