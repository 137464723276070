import React from 'react';
import {
  Alignment,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
} from '@blueprintjs/core';

const ExplorerNavigation = () => (
  <Navbar className={Classes.LIGHT}>
    <NavbarGroup align={Alignment.LEFT}>
      <NavbarHeading>Explorer la base des ZAE/EAE</NavbarHeading>
    </NavbarGroup>
  </Navbar>
);

export default ExplorerNavigation;
