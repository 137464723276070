import React from 'react';

import {
  SelectedFilters,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';

import { navigate } from 'gatsby';

import {
  Button,
  Intent,
} from '@blueprintjs/core';

import { Map, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { allFieldsBut } from '../helpers/explorerFilters';

const coordsToLatLng = coords => ({ lng: coords[0], lat: coords[1] });

const CustomMap = props => {
  const { hits } = props;

  return (
    <Map
      className="explorer-map-inner"
      style={{ minHeight: 100 }}
      center={[43.9168, 5.6322]}
      zoom={8}
      attributionControl={false}
    >
      <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" />

      {
        hits.map(({ _source: { geom_centroid: centroid, nom_ppal: name }, _id: id }) => {
          const { coordinates } = JSON.parse(centroid);
          return (
            <CircleMarker
              key={id}
              center={coordsToLatLng(coordinates)}
              radius={2}
            >
              <Popup>
                <h3>{name}</h3>
                <Button
                  onClick={({ button }) => {
                    const link = `/espaces-d-activites/${id}`;
                    return (button === 0)
                      ? navigate(link)
                      : window.open(link);
                  }}
                  intent={Intent.PRIMARY}
                >
                  Voir la fiche
                </Button>
              </Popup>
            </CircleMarker>
          );
        })
      }

    </Map>
  );
};

const ExplorerMap = () => (
  <div className="explorer-map">
    <ReactiveComponent
      componentId="customMap"
      react={{ and: allFieldsBut('customMap') }}
      defaultQuery={() => ({
        size: 2000,
        _source: {
          include: [
            'geom_centroid',
            'nom_ppal',
          ],
        },
      })}
    >
      <CustomMap />
    </ReactiveComponent>

    <SelectedFilters
      showClearAll
      clearAllLabel="Réinitialiser"
      className="selected-filters"
    />
  </div>
);

export default ExplorerMap;
