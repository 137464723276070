import React from 'react';

import {
  DataSearch,
  MultiList,
  RangeInput,
} from '@appbaseio/reactivesearch';

import classnames from 'classnames';

import {
  Card,
} from '@blueprintjs/core';

import { allFieldsBut } from '../helpers/explorerFilters';

const commonOptions = {
  innerClass: {
    title: 'filter-title',
    list: 'filter-list',
  },
};

const multiListOptions = {
  showSearch: true,
  className: 'filter',
  showMissing: true,
  missingLabel: 'Non renseigné',
};

const fieldsConfig = [
  {
    ReactiveComponent: DataSearch,
    ...commonOptions,
    title: 'Recherche',
    componentId: 'nom_ppal',
    placeholder: 'Chercher parmi les zones',
    highlight: true,
    autosuggest: false,
    dataField: ['nom_ppal', 'id_eae'],

    className: 'filter',
    wide: true,
  },
  {
    ReactiveComponent: RangeInput,
    ...commonOptions,
    title: 'Nombre d\'emplois',
    componentId: 'nb_emplois',
    dataField: 'nb_emplois',
    react: { and: allFieldsBut('nb_emplois') },
    tooltipTrigger: 'hover',
    showHistogram: false,
    showSlider: false,
    range: {
      start: 0,
      end: 30000,
    },
    defaultSelected: {
      start: 0,
      end: 30000,
    },

    // wide: true,
    className: 'filter',
  },
  {
    ReactiveComponent: RangeInput,
    ...commonOptions,
    title: 'Surface totale (ha)',
    componentId: 'surf_total',
    dataField: 'surf_total',
    // react: { and: allFieldsBut('surf_total') },
    tooltipTrigger: 'hover',
    showHistogram: false,
    showSlider: false,
    range: {
      start: 0,
      end: 10000,
    },
    defaultSelected: {
      start: 0,
      end: 10000,
    },

    // wide: true,
    className: 'filter',
  },
  {
    ReactiveComponent: MultiList,
    ...commonOptions,
    ...multiListOptions,
    title: 'Vocation',
    componentId: 'voc_regr',
    dataField: 'voc_regr.keyword',
    react: { and: allFieldsBut('voc_regr') },
  },
  {
    ReactiveComponent: MultiList,
    ...commonOptions,
    ...multiListOptions,
    title: 'EPCI',
    componentId: 'libepci',
    dataField: 'libepci.keyword',
    react: { and: allFieldsBut('libepci') },
  },

  {
    ReactiveComponent: MultiList,
    ...commonOptions,
    ...multiListOptions,
    title: 'Commune',
    componentId: 'nom_comm',
    dataField: 'nom_comm.keyword',
    react: { and: allFieldsBut('nom_comm') },
  },

  {
    ReactiveComponent: MultiList,
    ...commonOptions,
    ...multiListOptions,
    title: 'Département',
    componentId: 'nom_dept',
    dataField: 'nom_dept.keyword',
    react: { and: allFieldsBut('nom_dept') },
  },

];

const ExplorerFilters = () => (
  <div className="explorer-filters">

    {
      fieldsConfig.map(({ ReactiveComponent, wide, ...props }) => (
        <Card key={props.componentId} className={classnames({ wide, 'filter-card': true })}>
          <ReactiveComponent {...props} />
        </Card>
      ))
    }
  </div>
);

export default ExplorerFilters;
