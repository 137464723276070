export const allFieldsBut = except => ([
  'nom_ppal',
  'nb_emplois',
  'voc_regr',
  'libepci',
  'nom_comm',
  'nom_dept',
  'customMap',
  'surf_total',
].filter(field => field !== except));

export default {
  allFieldsBut,
};
